<template>
  <div class="theory-container-one">
    <div class="theory-container-header">
      <div class="training-main">
        <span class="training-title">所属训练:</span>
        <el-select
          v-model="selectValue"
          placeholder="全部"
          clearable
          style="margin-left: 9px"
          @change="changeListData"
        >
          <el-option
            v-for="item in trainingSelect"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="update-time">
        <span style="margin-right: 10px">数据更新时间：{{ updateTime }}</span>
        <i @click="refreshPage" style="cursor: pointer" class="iconfont"
          >&#xe674;</i
        >
      </div>
    </div>
    <el-table
            :data="trainingList"
            stripe
            height="100%"
            border
            :cell-style="{ color: 'rgba(52, 52, 65, 1)', borderRight: 'unset' }"
            :header-cell-style="{
            color: 'rgba(19, 19, 27, 1)',
            background: '#F3F4FA',
            borderRight: 'unset',
          }"
    >
      <el-table-column
              prop="name"
              label="理论题库分类"
              width="220"
              align="center"
      >
      </el-table-column>
      <el-table-column
              prop="train_name"
              label="训练名称"
              width="220"
              align="center"
      >
      </el-table-column>
      <el-table-column
              prop="student_class_name"
              label="训练班级"
              width="220"
              align="center"
      >
      </el-table-column>
      <el-table-column
              prop="class_num"
              label="训练人数"
              width="220"
              align="center"
      >
      </el-table-column>
      <el-table-column
              prop="start_time"
              label="开始时间"
              width="220"
              align="center"
      >
      </el-table-column>
      <el-table-column
              prop="end_time"
              label="结束时间"
              width="230"
              align="center"
      >
      </el-table-column>
      <el-table-column
              prop="options"
              label="操作"
              width="229"
              align="center"
      >
        <template slot-scope="scope">
          <el-button @click="goToDetail(scope.row)" type="text" size="small"
          >查看详情</el-button
          >

          <el-button
                  type="text"
                  size="small"
                  @click="delItem(scope.row)"
                  style="color: red"
          >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
            class="pages-center"
            :current-page="listPages.currentPageNum"
            :page-size="listPages.eachPageNum"
            layout="prev, pager, next, jumper"
            :total="listPages.total"
            @current-change="pageCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { showTheoryListData, delTheoryListData } from "@/utils/apis";
export default {
  data() {
    return {
      updateTime: "", //数据更新时间
      trainingSelect: [],
      trainingList: [],
      selectValue:localStorage.getItem('selectVal')?Number(localStorage.getItem('selectVal')):'',
      role: null,
      show: 0,
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
    };
  },
  beforeDestroy() {
    if(!(this.$route.name==='DataCenterStudentTheoryList')){
                localStorage.removeItem('selectVal')
            }
  },
  created() {
    this.acquisitionTime();
  },
  mounted() {
    let role = localStorage.getItem("role");
    this.role = role;
    this.showTheoryList();
  },
  methods: {
    showTheoryList() {
      let params = {
        paging: "1",
        pageSize: this.listPages.eachPageNum,
        page: this.listPages.currentPageNum,
      };
      showTheoryListData(params).then((res) => {
        if (res.code === 200) {
          this.trainingList = res.data.list;         
          this.listPages.total = res.data.total;
          this.trainingSelect = res.data.class_data;
        }
      });
    },
    // 切换分页
    pageCurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.showTheoryList();
    },

    // 查看详情
    goToDetail(row) {
      this.$router.push({
        path:'/trainadmin/datacenter/studenttheoryList',
        query:{
          train_id:row.id
        }
      })
    },
    // 查询
    changeListData() {
      localStorage.setItem('selectVal',this.selectValue)
      let params = {
        paging: "1",
        class_id: this.selectValue,
        pageSize: this.listPages.eachPageNum,
        page: this.listPages.currentPageNum,
      };
      showTheoryListData(params).then((res) => {
        if (res.code === 200) {
          this.trainingList = res.data.list;
        
          this.listPages.total = res.data.total;
        }
      });
    },
    delItem(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: row.id,
          };
          delTheoryListData(params).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.showTheoryList();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    acquisitionTime() {
      let time = new Date();
      this.updateTime = this.dateFormatTwo(time);
    },
    refreshPage() {
      //     更新列表后刷新更新时间
      this.acquisitionTime();
      this.showTheoryList();
      this.$message({
        message: "刷新成功",
        type: "success",
      });
    },
    dateFormatTwo: function (time) {
      var date = new Date(time);
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.theory-container-one {
  ::v-deep .el-button--text {
    color: rgba(17, 34, 216, 1);
  }
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  .theory-container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .training-main {
      .training-title {
        margin-left: 4px;
        font-weight: bold;
      }
    }
  }
  .el-table {
    flex:1;
    margin-top:20px;
    ::v-deep .el-table th.el-table__cell {
      background: rgba(245, 245, 251, 1);
    }
    .pages-center {
      margin-top: 15px;
      text-align: center;
    }
  }
  .pages-center {
    text-align: center;
    margin-top: 20px;
  }
}
</style>